import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ExpandMore from '@mui/icons-material/ExpandMore';

import './faqscomponent.css';

// Accordion Styling
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

// Accordion Question Styling
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMore sx={{ fontSize: '2em' }} />}
        {...props}
    />
    ))(({ theme }) => ({
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
            fontFamily: 'Raleway, sans-serif',
        },
    }));

// Accordion Answer Styling
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(.5)
    }));

const FaqsComponent = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className='accordion-container'>
            {/* First Question */}
            <Accordion className='accordion' expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{background: 'rgba(106, 165, 236, 1)', border: '1px solid #FFFFFF', marginBottom: '5px'}}>

                <AccordionSummary
                    expandIcon={<ExpandMore style={{color: '#6AA5EC'}}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{backgroundColor: 'black', color: '#FFFFFF'}}
                >

                    <h4 className='accordion-question'>
                        What is ceramic coating?
                    </h4>
                </AccordionSummary>

                <AccordionDetails sx={{color: '#FFFFFF'}}>
                    <p className='accordion-answer'>
                        Ceramic coating is a chemical composition made up of Silicon Carbide (SiC). When applied to a cleaned and decontaminated surface, the coating becomes a durable protectant from future exposure to weather, ocean salt, iron deposit etc.
                    </p>
                </AccordionDetails>
            </Accordion>

            {/* Second Question */}
            <Accordion className='accordion' expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{background: 'rgba(106, 165, 236, 1)', border: '1px solid #FFFFFF', marginBottom: '5px'}}>

                <AccordionSummary
                    expandIcon={<ExpandMore style={{color: '#6AA5EC'}}/>}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    style={{backgroundColor: 'black', color: '#FFFFFF'}}
                >

                    <h4 className='accordion-question'>
                        How long does a ceramic coating last?
                    </h4>
                </AccordionSummary>

                <AccordionDetails sx={{color: '#FFFFFF'}}>
                    <p className='accordion-answer'>
                        With the products that we use, clients will have the option to opt to a 3, 5 and 7 year ceramic coating.
                    </p>
                </AccordionDetails>
            </Accordion>

            {/* Question 3 */}

            <Accordion className='accordion' expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{background: 'rgba(106, 165, 236, 1)', border: '1px solid #FFFFFF', marginBottom: '5px'}}>

                <AccordionSummary
                    expandIcon={<ExpandMore style={{color: '#6AA5EC'}}/>}
                    aria-controls="panel3-content"
                    id="panel3-header"
                    style={{backgroundColor: 'black', color: '#FFFFFF'}}
                >

                    <h4 className='accordion-question'>
                    What is paint correction and why is it needed?
                    </h4>
                </AccordionSummary>

                <AccordionDetails sx={{color: '#FFFFFF'}}>
                    <p className='accordion-answer'>
                    Paint correction (Polish) eliminates surface scratching, micro scratches, oxidation and also gives the ceramic coating a consitent surface to bond to. When all contaminants are removed and leveled, this allows the coating to truly work at its fullest potential.
                    </p>
                </AccordionDetails>
            </Accordion>

            {/* Question 4 */}
            <Accordion className='accordion' expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{background: 'rgba(106, 165, 236, 1)', border: '1px solid #FFFFFF', marginBottom: '5px'}}>
                <AccordionSummary
                        expandIcon={<ExpandMore style={{color: '#6AA5EC'}}/>}
                        aria-controls="panel4-content"
                        id="panel4-header"
                        style={{backgroundColor: 'black', color: '#FFFFFF'}}
                    >

                    <h4 className='accordion-question'>
                        How long does it take for ceramic coating to cure?
                    </h4>
                </AccordionSummary>

                <AccordionDetails sx={{color: '#FFFFFF'}}>
                    <p className='accordion-answer'>
                        Most ceramic coatings typically take around 7-10 days depending on the product. This allows the coating to fully harden and ensure a strong bond between the product and painted surface.
                    </p>
                </AccordionDetails>
            </Accordion>

            {/* Question 5 */}
            <Accordion className='accordion' expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{background: 'rgba(106, 165, 236, 1)', border: '1px solid #FFFFFF', marginBottom: '5px'}}>
                <AccordionSummary
                        expandIcon={<ExpandMore style={{color: '#6AA5EC'}}/>}
                        aria-controls="panel5-content"
                        id="panel5-header"
                        style={{backgroundColor: 'black', color: '#FFFFFF'}}
                    >

                    <h4 className='accordion-question'>
                      Does ceramic coatings require special maintenance? 
                    </h4>
                </AccordionSummary>

                <AccordionDetails sx={{color: '#FFFFFF'}}>
                    <p className='accordion-answer'>
                        Ceramic coated vehicles do not require any special treatment. As any other vehicle, hand wash the vehicle with non abrasive materials every 2-3 week to prevent contaminant build up. 
                    </p>
                </AccordionDetails>
            </Accordion>

            {/* Question 6 */}
            <Accordion className='accordion' expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{background: 'rgba(106, 165, 236, 1)', border: '1px solid #FFFFFF', marginBottom: '5px'}}>
                <AccordionSummary
                        expandIcon={<ExpandMore style={{color: '#6AA5EC'}}/>}
                        aria-controls="panel6-content"
                        id="panel6-header"
                        style={{backgroundColor: 'black', color: '#FFFFFF'}}
                    >

                    <h4 className='accordion-question'>
                        What should I avoid after installing a ceramic coating?
                    </h4>
                </AccordionSummary>

                <AccordionDetails sx={{color: '#FFFFFF'}}>
                    <p className='accordion-answer'>
                        Ceramic coatings do add another layer of protection, but that doesn't bullet proof your paint. Avoiding taking your vehicle to automated car washes and abrasive wash mitts & towels. These can not only damage the coating, but also can damage your paint.
                    </p>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default FaqsComponent;