import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import Card from '../../components/Card/Card';
import { responsive, serviceData } from '../../data';
import Car from '../../images/car2.jpg';
import 'react-multi-carousel/lib/styles.css';
import './services.css';
import { Link } from 'react-router-dom';
import Aos from 'aos';





const Services = () => {
    const service = serviceData.map((item, index) => (
        <Card
            key={index}
            name={item.name}
            image={item.image}
            price={item.price}
            description={item.description}
        />
    ))

    useEffect(() => {
        window.scroll(0,0)
    }, []);

    useEffect(() => {
        Aos.init({
            duration: "1500"
        }, []);
    })

    return (
        <div className='services-page' data-aos="fade-in">  
            <div className='services-head__wrapper' style={{backgroundImage: `url(${Car})`}}>
                <div className='services-head__tint'>
                    <h2 className='services-header'>DETAILING SERVICES</h2>
                </div>
            </div>
            <div className='carousel-wrapper' data-aos="slide-left" data-duration-aos="4000">
                <Carousel 
                    responsive={responsive} 
                    showDots={false} 
                    swipeable={true}
                >
                    {service}
                </Carousel>
            </div>

            <section className='services-bottom__container' data-aos="fade-in">
                <p className='services-bottom__typography'>
                    <b>Need something more specific?</b> We also consider customer specific services. Don't hesitate to contact us and we'll do our best to help answer all your questions and find what is best fitting for your detailing needs.
                </p>

                <Link to='/contact' className='services-message__wrapper'>
                    <button className='services-message__btn'>Message Us</button>
                </Link>
            </section>
        </div>
    )
}

export default Services;