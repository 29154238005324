export const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1080 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1080, min: 700 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 699, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  export const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 1,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 699, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

export const serviceData = [
    {
        id:1,
        image: './assets/ceramic.jpg',
        name: 'Ceramic Coating',
        price: 'Starting from $1500',
        description: "Includes a wash and paint decontamination to remove any embedded dirt and contaminants. It is followed by paint correction and paint surface prep for a flawless installation."

    },
    {
        id:2,
        image: './assets/wheels.jpg',
        name: 'Full Detail Package',
        price: 'Starting from $250 and up',
        description: 'Includes a wash, clay bar, wheel/wheel well cleaning, door jamb cleaning, 1 year sealant, window cleaning, and tire shine. It also includes vacuum cleaning, and interior panel/trim detail.'
    },
    {
        id:3,
        image: './assets/pressureWash.jpg',
        name: 'Basic Detail Package',
        price: 'Starting from $150 and up',
        description: 'This package includes a exterior wash, wheel cleaning, followed by spray wax and tire shine. The interior will be vacuumed, as well as a panel/trim and window cleaning.'
    },
    {
        id:4,
        image: './assets/washingcar.jpg',
        name: 'Exterior Detail Package',
        price: 'Starting from $200 and up',
        description: 'This package includes a thorough wash, light water spot removal, clay bar treatment, door jamb cleaning, 1 year paint sealant, wheel & wheel well clean, finished off with a tire shine. '
    },
    {
        id:5,
        image: './assets/interior.jpg',
        name: 'Interior Detail',
        price: 'Starting from $80 and up',
        description: 'This service includes full interior vacuuming, interior panel & trim cleaning/shining, stain removal and glass cleaning. We can also provide more indepth cleaning if stains persist.'
    }
    
]