import { React, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Truck from '../../images/truck-min.jpg';
import Aos from "aos";
import './about.css';

const About = () => {
    
    useEffect(() => {
        window.scroll(0,0)
    }, []);

    useEffect(() => {
        Aos.init({
            duration: "1500",
        }, []);
    })

    return (
        <div className="contact-page" data-aos="fade-in">
            
            <div className="about__header" style={{backgroundImage:`url(${Truck})`}} >
                <div className="about__tint">
                    <h1 className="about-heading">ABOUT US</h1>
                </div>
            </div>
            
            <div className="mission__container" >
                <h2 className="mission-header" data-aos="fade-in" data-duration-aos="4500">
                    Our Mission
                </h2>
                <p className="mission__paragraph" data-aos="slide-left" data-duration-aos="4000">
                    At Precision Detailing Hawaii, our mission is to provide the highest quality auto detailing services to our customers with a commitment to excellence, professionalism, and customer satisfaction. Founded in 2018 by Noah Malalis, our team is dedicated to providing exceptional service, using only the finest products and techniques to ensure that each vehicle we detail is treated with the care and attention it deserves.<br/>
                    <br/>
                    Our goal is to create a positive experience for each customer, delivering exceptional results and building long-term relationships based on trust and respect. We believe in treating each vehicle as if it were our own, and strive to exceed our customers' expectations with every detail.<br/>
                    <br/>
                    Through our dedication to quality, professionalism, and customer service, we aim to become the go-to auto detailing company in Hawaii, and to be recognized as a leader in the industry for our commitment to excellence.
                </p>
                
            </div>


            <div className="how__container">
                <h2 className="how-header" data-aos="fade-in">How We Started</h2>
                <p className="how__paragraph" data-aos="slide-right">
                    Precision Detailing Hawaii all start on the east side of Oahu after founder, Noah Malalis, purchased his first new vehicle, and quickly became obsessed with keeping it in pristine condition.<br/>
                    <br/>
                    He started by spending countless hours researching different detailing techniques and products, and began experimenting with different methods to achieve the best possible results. As he continued to hone his skills, he then started detailing friends' and family members' vehicle purely as a hobby. However, as word of mouth spread about the quality of his work, he began receiving more and more requests for his services. Thats when he decided to take the leap and officially launch Precision Detailing Hawaii.<br/>
                    <br/>
                    Today, Precision Detailing Hawaii continues to provide top-notch detailing services to customers throughout Oahu, and we're constantly striving to improve and evolve. With the love and passion of detailing, Precision Detailing Hawaii has got you covered... 
                </p>
            </div>
        </div>
    )
}

export default About;