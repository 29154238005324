import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Turn as Hamburger } from 'hamburger-react';
import Instagram from '@mui/icons-material/Instagram';
import Phone from '@mui/icons-material/Phone';
import boxlogo from '../../images/navboxlogo.png';
import './navbar.css';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll)

    }, []);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        console.log('triggered');
    };

    const handleItemClick = () => {
        setIsMenuOpen(false);
    }

    return (
        <>
            <nav className={isScrolled ? "navbar scrolled" : "navbar"}>
                <div className='logo'>
                    <Link to='/'>
                        <img className='boxlogo' src={boxlogo} alt='logo' onClick={handleItemClick} />
                    </Link>
                </div>
                <div className='hamburger' onClick={toggleMenu}>
                    <Hamburger direction='right' toggled={isMenuOpen} toggle={setIsMenuOpen}/>
                </div>
                <ul className={`nav-menu ${isMenuOpen ? 'is-open' : ''}`}>
                    <li>
                        <a href="/#gallery" className='nav__item' onClick={handleItemClick}>GALLERY</a>
                    </li>
                    <li>
                        <Link to="/ceramic" className='nav__item' onClick={handleItemClick}>CERAMIC COATING</Link>
                    </li>
                    <li>
                        <Link to ='/services' className='nav__item' onClick={handleItemClick}>SERVICES</Link>
                    </li>
                    <li>
                        <Link to='/about' className='nav__item' onClick={handleItemClick}>ABOUT</Link>
                    </li>
                    <li>
                        <a className='icon__link' href='tel:+18089535624'><Phone /></a>
                    </li>
                    <li>
                        <a className='icon__link' href="https://www.instagram.com/precisiondetailinghawaii/?hl=en" target="_blank" rel="noopener noreferrer" alt="Precision Detailing Hawaii Instagram"><Instagram /></a>
                    </li>
                    <Link to='/contact'><button className='nav__button' onClick={handleItemClick}>CONTACT US</button></Link>
                </ul>
            </nav>
        </>
    )
}


export default Navbar;
