import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel2 from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { FaCheck } from "react-icons/fa6";
import { responsive2 } from "../../data";
import logo from '../../images/logotransparent.png';
import image1 from '../../images/image1.jpeg';
import image2 from '../../images/image2.jpg';
import image3 from '../../images/image3.jpg';
import image4 from '../../images/image4.jpg';
import image5 from '../../images/image5.JPG';
import image6 from '../../images/image6.jpg';
import image7 from '../../images/image7.jpg';
import image8 from '../../images/image8.jpg';
import image9 from '../../images/image9.JPG';
import image10 from '../../images/image10.jpg';
import image11 from '../../images/image11.jpg';
import image12 from '../../images/image12.jpg';
import image13 from '../../images/image13.JPG';
import image14 from '../../images/image14.JPG';
import hood from '../../images/hood.JPG';
import ReviewWidget from "../../components/ReviewWidget/ReviewWidget";
import AOS from "aos";
import 'aos/dist/aos.css';
import './home.css';




const Home = () => {
    
    useEffect(() => {
        window.scroll(0,0)
    }, []);

    AOS.init({
        duration: "1500"
    }, []);

    return (
        <>
            <div className="head" data-aos="fade-in">
                <div className="header__container" style={{backgroundImage: `url(${hood})`}}>
                    <div className="header__content" >
                        <div className="logo-container">
                            <img src={logo} alt="precision detailing hawaii logo" />
                        </div>
                        <h2>WHERE EVERY DETAIL MATTERS</h2>
                        <div className="button__div">
                            <Link to='/contact' className="button__container">
                                <button className="lower__btn">
                                    SCHEDULE TODAY
                                </button>
                            </Link>  
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="gallery__section">
                <div className="gallery__main"> 
                    <section className="gallery_new">
                        <h2 className="gallery-header">
                            OAHU'S PREMIERE <br />
                            <span>AUTOMOTIVE DETAILER</span>
                        </h2>
                        <div className="carousel-wrapper" data-aos="slide-up">
                            <div className="gallery__carousel" id="gallery">    
                                    <Carousel2
                                        containerClass="newCarousel"
                                        infinite={true}
                                        swipeable={true}
                                        draggable={true}
                                        autoPlay={true}
                                        renderDotsOutside={false}
                                        arrows={true}
                                        responsive={responsive2}
                                        centerMode={true}
                                        focusOnSelect={true}
                                        transitionDuration={200}
                                    >
                                        <div className="pic-card">
                                            <img src={image1} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image2} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image3} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image4} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image5} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image6} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image7} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image8} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image9} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image10} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image11} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image12} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image13} className='carousel__item' alt='detailed car' />
                                        </div>
                                        <div className="pic-card">
                                            <img src={image14} className='carousel__item' alt='detailed car' />
                                        </div>
                                    </Carousel2>
                            </div>
                        </div>
                        
                        <div className="gallery__content" data-aos="fade-in" data-duration-aos="4500">   
                            <div className="offers-wrapper">
                                <div className="gallery-offers">
                                    <FaCheck size={20} color="#0081E4" />
                                    <p className="offers-caption">
                                        CERAMIC COATINGS
                                    </p>
                                </div>
                                <div className="gallery-offers">
                                    <FaCheck size={20} color="#0081E4" />
                                    <p className="offers-caption">
                                        INTERIOR DETAILING
                                    </p>
                                </div>
                                <div className="gallery-offers">
                                    <FaCheck size={20} color="#0081E4" />  
                                    <p className="offers-caption">
                                        ROUTINE DETAILING
                                    </p>
                                </div>
                                <div className="gallery-offers">
                                    <FaCheck size={20} color="#0081E4" />  
                                    <p className="offers-caption">
                                        PAINT CORRECTION
                                    </p>
                                </div>
                            </div>
                            
                            
                            <p>
                                Using the most quality products we assure top care of your vehicle. We offer different detailing services including ceramic coatings, paint correction, or even a routine wash and wax. Contact us today to schedule an appointment and give your car what it deserves: A <i>Precision Detailing Hawaii Shine.</i>
                            </p>
                            <Link to="/services">
                                <button className="services-btn">
                                    What We Offer
                                </button>
                            </Link>
                        </div>
                    </section>   
 
                    <div className="review__section" data-aos="fade-in" data-duration-aos="4000">
                        <ReviewWidget />
                    </div>
                
                    <div className="contact__info">
                        <address>
                            Noah Malalis | PDH Owner<br/>
                            <br/>
                            Hawaii Kai, Oahu<br/>
                            +1(808)953-5624<br/>
                            precisiondetailinghawaii@gmail.com<br/>
                            @precisiondetailinghawaii
                        </address> 
                    </div> 
                </div>
            </div>
        </>
    )
}

export default Home;
