import React from "react";

import './footer.css';

const Footer = () => {
    return (
        <footer>
            ©Precision Detailing Hawaii  2023
        </footer>
    )
}

export default Footer;