import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import AOS from "aos";
import 'aos/dist/aos.css';
import  {GiCutDiamond, GiTakeMyMoney, GiClockwork}  from "react-icons/gi";
import FaqsComponent from '../../components/FaqsComponent/FaqsComponent';
import CarPic from '../../images/ceramic-car.jpg';
import CeramicBenefits from '../../images/ceramic-benefits.png';
import opLogo from '../../images/op-logo.png';
import opSmall from '../../images/op-logo-small.png'
import './ceramic.css';

const Ceramic = () => {

    useEffect(() => {
        window.scroll(0,0)
    }, []);

    useEffect(() => {
        AOS.init({
            duration: '1500'
        }, []);
    });
    
    return (
        <>
          <div className="ceramic-container" data-aos='fade-in'>
            <section className="ceramic-head" style={{backgroundImage: `url(${CarPic})`}}>
                <div className="ceramic-head-tint">
                <h1 className="ceramic-header">
                    CERAMIC COATING SERVICES
                </h1>
                </div>
            </section>
            <section className="ceramic-main">
                <h2 className="why-ceramic">
                    Benefits of Ceramic Coating
                </h2>
                <div className="ceramic-benefits">
                    <img src={CeramicBenefits} alt="car with descriptions of what ceramic coating protects against"/>
                </div>
                <div className="ceramic-card-wrapper" data-aos="slide-left" data-duration-aos="4000">
                    
                    <div className="ceramic-card" id="card1">
                        <GiCutDiamond size={60} color="#6AA5EC"/>
                        <hr  className="ceramic-card-line"/>
                        <h3 className="ceramic-card-header">
                            Protection & Perfection
                        </h3>
                        <p className="ceramic-card-description">
                            Ceramic coating adds a protective coating layer to your new or pre-existing paint, allowing the paint to also have a near perfect shine. 
                        </p>
                    </div>
                    <div className="ceramic-card" id="card2">
                        <GiTakeMyMoney size={60} color="#6AA5EC" />
                        <hr  className="ceramic-card-line"/>
                        <h3 className="ceramic-card-header">
                            Save More Over Time
                        </h3>
                        <p className="ceramic-card-description">
                            With your paint protected with a ceramic coating, it reduces the amount of money spent on fading paint, consistent cleaning, and trying to upkeep the health of your paint. 
                        </p>
                    </div>
                    <div className="ceramic-card" id="card3">
                        <GiClockwork size={60} color="#6AA5EC" />
                        <hr  className="ceramic-card-line"/>
                        <h3 className="ceramic-card-header">
                            Lesser Time, Better Shine
                        </h3>
                        <p className="ceramic-card-description">
                            With a ceramic coating, routine washing and upkeep takes lesser amounts of time, all while ultimately saving you time spent on cleaning. 
                        </p>
                    </div>
                </div>
            </section>
            <section className="ceramic2-container"  data-aos="fade-in" data-duration-aos="4000">
                <div className="ceramic2-wrapper">
                    <h2 className="ceramic2-header" data-aos="fade-left" data-duration-aos="4500">
                        Precise Detailing, <span className="ceramic2-header2">Quality Products</span>
                    </h2>
                    <div className="ceramic2-logo__wrapper2">
                        <div className="op-logo2">
                            <img src={opSmall} alt="Owners Pride certified installer"/>
                        </div>
                        <h2 className="ceramic2-header-small">
                            Precise Detailing, <span className="ceramic2-header2">Quality Products</span>
                        </h2>

                    </div>
                    <p className="ceramic2-subheading">
                        Using only the best products, we make sure that every vehicle is done the right way from start to finish. Precision Detailing Hawaii is proud to announce that we offer 3, 5, and 7 year ceramic coatings as an Owner's Pride authorized installer. All ceramic installations done with Owner's Pride products are backed with warranty, assuring quality and longevity. 
                    </p>
                    <a className="op-btn" href="https://ownerspride.com/" rel="noreferrer" target="_blank">View OP Products</a>
                </div>
                <div className="ceramic2-logo__wrapper">
                    <img className="op-logo" src={opLogo} alt="Owners Pride certified installer"/>
                </div>
            </section>
            <section className="ceramic-faqs" data-aos="fade-in" data-duration-aos="4000">
                <h3 className="ceramic-faqs-header">
                    Ceramic Coating FAQS
                </h3>
                <div className="faqs-wrapper">
                    <FaqsComponent />
                </div>
                <p className="ceramic-faqs-subheading">
                    Ceramic coats come with tons of questions. If you still have unanswered questions, contact us and we'll do our best to make you feel 100% confident about taking the next step, the Precision Detailing way.
                </p>
                <Link to='/contact'>
                    <button className="ceramic-faqs-btn">
                        Contact Us Today
                    </button>
                </Link>
            </section>
          </div>
        </>
    )
}

export default Ceramic;