import React from 'react';

export default function Card(props) {
  return(
    <div className='card'>
      <img
        className='card__image'
        src={props.image}
        alt='services'
      />
      <h2 className='service-type'>{props.name}</h2>
      <hr className='card-line' />
      <p className='price'>{props.price}</p>
      <p className='description'>{props.description}</p>
    </div>
  )
}