import { React, useEffect, useRef } from 'react';
import { Typography, Card, CardContent, Grid, TextField, Button } from '@mui/material';
import emailjs from 'emailjs-com';
import blackCar from '../../images/blackCar.jpg';
import { FaPhoneSquareAlt } from "react-icons/fa";
import { FaSquareGooglePlus } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import './contact.css';
import Aos from 'aos';

const Contact = () => {
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_hvo0hvh', form.current, 'b5OGje5H75o738pL8')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      
      e.target.reset()
    };

    useEffect(() => {
        window.scroll(0,0)
    }, []);

    useEffect(() => {
        Aos.init({
            duration: "1500"
        },[]);
    })

    return(
        <div className='contact-page' data-aos="fade-in">
            <div className='contact-head' style={{backgroundImage: `url(${blackCar})`}}>
                <div className='contact-tint'>
                    <h1 className='contact-header'>
                        CONTACT US
                    </h1>
                </div>
            </div>
            <section className='contact-main'>
                <h2 className='contact-info-header2' data-aos="fade-right">
                    Every Detail Matters, <br/>
                    <span data-aos="fade-in">Including Your Questions</span>
                </h2>
                <div className='contact-info-wrapper' data-aos="fade-right">
                    <h2 className='contact-info-header'>
                        Every Detail Matters, <br/>
                        <span data-aos="fade-in" >Including Your Questions</span>
                    </h2>
                    <p className='contact-info-subheading'>
                        Whether you are ready to schedule a detail for your vehicle, or have an unanswered question, we'll do our absolute best to give you the reassurance that you need. Send us a message or give us a call at any time Monday through Friday, and let us give your car the detail that it deserves.
                    </p>
                    <div className='contact-icons-wrapper'>
                        <div className='contact-icon-link'>
                            <FaPhoneSquareAlt size={30} style={{paddingLeft: "5px"}} />
                            <a className='contact-icon' href='tel:+18089535624'>
                                808.953.5624
                            </a>
                        </div>
                        <div className='contact-icon-link'>
                            <FaSquareGooglePlus  size={30} style={{paddingLeft: "5px"}}/>
                            <a className='contact-icon' href='https://g.co/kgs/bNbePf2' target='_blank' rel='noopener noreferrer' alt='google review'>Reviews</a>
                        </div>

                        <div className='contact-icon-link'>
                            <FaInstagramSquare  size={30} style={{paddingLeft: "5px"}}/>
                            <a className='contact-icon' href="https://www.instagram.com/precisiondetailinghawaii/?hl=en" target="_blank" rel="noopener noreferrer" alt="Precision Detailing Hawaii Instagram">PDH Insta</a>
                        </div>
                    </div>
                </div>
                <div className='contact-wrapper' data-aos="fade-left">
                    <Card style={{maxWidth: "100%", margin: "0 auto", padding: "10px 10px", boxShadow: "0 3px 15px rgba(0, 0, 0, 0.3)"}}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' sx={{fontFamily: "Raleway, sans-serif"}}>Schedule a detail today!</Typography>
                            <form ref={form} onSubmit={sendEmail}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField label="Name" placeholder='Enter first and last name' name='name' variant='outlined' fullWidth required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Service" placeholder='Enter which service' name='service' variant='outlined' fullWidth required/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField type="email" label="Email" placeholder='Enter email' name='email' variant='outlined' fullWidth required/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField type="number" label="Phone" placeholder='Enter phone number' name='phone' variant='outlined' fullWidth required/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Message" multiline rows={4} placeholder='Type message here' name='message' variant='outlined' fullWidth required/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button className='submit__button' type='submit' variant='contained' fullWidth style={{backgroundColor: "#0081E4"}}>Submit</Button>
                                    </Grid>
                                </Grid> 
                        </form> 
                        </CardContent>
                    </Card>    
                </div>    
            </section>
        </div>
    )
}

export default Contact;
